export let projects = [
    {
        name: "Study Rush",
        image: "https://imgur.com/2qRdLfj.png",
        description:"Introducing Study Rush, the modern, fully AI-integrated flashcard application. With our AI solutions, we transform the tedious process of creating study materials into an effortless, intuitive experience. Spend less time rewriting your notes and more time actively practicing your material.",
        date : "June 2024",
        links: {
            Github: "https://github.com/JamesVong/flashcard-app/",
            Devpost: "https://devpost.com/software/study-rush",
            Live:"https://studyrush-x962eo7k.b4a.run/",
        }
    },
    {
        name: "Dots and Boxes",
        image: "https://i.imgur.com/8kNSE7W.png",
        description:"Play the Dots and Boxes games with your friends online.",
        date : "June 2024",
        links: {
            Github: "https://github.com/8coolguy/dotsAndBoxes",
            Devpost: "",
            Live:"https://dotsandboxes-ru0u37z6.b4a.run/",
        }
    },
    {
        name: "Virtual Cycling Rooms",
        image: "https://i.imgur.com/TYkSmjg.png",
        description:"Create virtual cycling room where players can compete against other rider virtually. Connects to bluetooth senors to show speed,cadence,distance and heart rate.",
        date : "May 2024",
        links: {
            Github: "https://github.com/8coolguy/leaderboard_app",
            Devpost: "",
            Live:"https://leaderboardapp1-r7310cyz.b4a.run/",
        }
    },
    {
        name: "Progress Planted",
        image: "https://i.imgur.com/CqNO8kk.png",
        description:"Website that helps you plan projects with a graph nodes and edges.",
        date : "February 2023",
        links: {
            Github: "https://github.com/shur1m/ProgressPlanted",
            Devpost: "https://devpost.com/software/progressplanted",
            Live:"https://shur1m.github.io/ProgressPlanted/",
        }
    },
    {
        name: "Chill Spaces",
        image: "https://i.imgur.com/wjhMVRO.png",
        description:"Website that helps you find the quietest parts of a city. Built with React, Firebase, and Flask.",
        date : "November 2022",
        links: {
            Github: "",
            Devpost: "https://devpost.com/software/chillspaces",
            Live:"https://shur1m.github.io/InrixHack2022/",
        }
    },
    {
        name: "Carbon Footprint Tracker",
        image: "https://i.imgur.com/czxFKmU.jpg",
        description:"Website that helps you track and visualize your carbon footprint by tracking six categories of usage. Built with React and Firebase.",
        date : "July 2022",
        links: {
            Github: "https://github.com/8coolguy/carbon-footprint-web",
            Devpost: "",
            Live:"https://carbon-footprint-web.vercel.app/",
        }
    },
    {
        name: "Blog Graph",
        image: "https://imgur.com/VFgIFUX.png",
        description:"Website that helps you blog with a graph visualization. Built with React, Mongo and AWS.",
        date : "June 2022",
        links: {
            Github: "https://github.com/8coolguy/BlogGraph",
            Devpost: "",
            Live:"",
        }
    },
    {
        name: "California Predictive Fire Model",
        image: "https://i.imgur.com/wjhMVRO.png",
        description:"With CAL Fire data of the past 10 years of wildfires, weather data, and local county data, I am trying to build a predictive fire model that tries to predict the size of a wild fire based on given variables.",
        date : "December 2021",
        links: {
            Github: "https://github.com/8coolguy/cali-fire-analysis",
            Devpost: "",
            Live:"",
        }
    },
    // {
    //     name: "Paper Prisons Crime Classifiers Model",
    //     image: "https://i.imgur.com/yG7f32h.png",
    //     description:"Built a language processor that would be able to classify whether a criminal charge can be expunged or not. Able to classify 50% of the given datasets as expungeable or non-expungeable crime.",
    //     date : "October 2021",
    //     links: {
    //         Github: "https://github.com/8coolguy/cali-fire-analysis",
    //         Devpost: "",
    //         Live:"",
    //     }
    // },
    {
        name: "Rabbit Ecosystem Simulation",
        image: "https://i.imgur.com/7WkeEGY.png",
        description:"Programmed a simulation of a rabbit ecosystem where I tracked how it reacted to external variables like predators, water, land features. Developed a Q learning reinforcement model for each rabbit to make decisions based on their surroundings. Utilized the concepts of polymorphism and inheritance to effectively create simulated animals.",
        date : "August 2021",
        links: {
            Github: "https://github.com/8coolguy/eco-system-sim",
            Devpost: "",
            Live:"",
        }
    },
    {
        name: "Proceduraly Generated Solar System",
        image: "https://i.imgur.com/6vtGrmj.gif",
        description:"Created a simulation using Unity that can proceduraly generate a solar system with unique planets and stars. Implemented different methods of coherent noise to planets to generate unique terrain on planets.",
        date : "June 2021",
        links: {
            Github: "https://github.com/8coolguy/pcg-solar-system",
            Devpost: "",
            Live:"https://8coolguy.itch.io/procedural-solar-system-generation",
        }
    },
]

export let experiences = [
    {
        title: "Odoo Full Stack Intern",
        date:{
            start: "June 2023",
            end: "September 2023"
        },
        description:"Implemented 445 accounting schedule that affected platform functionality, resulting in more accurate financial reporting with unique schedules. Engaged with a sizable code repository, focusing on existing fiscal quarters to create a module that lets businesses create unique fiscal schedules. Ensured code quality and robustness by creating unit tests for modules incorporated into client solutions.",

    }
]
export let about = "                  I am currently an  Computer Science and Engineering Grad. and I spend time building personal projects to improve my skills Right now I am focusing on Backend Development and Fullstack development, but I also have interests in Game Development and Machine Learning. Contact me at achoudhury2@scu.edu.";
